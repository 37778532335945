import gql from 'graphql-tag';

/* eslint-disable import/prefer-default-export */

// get availability of a products variants
export const GET_VARIANTS_AVAILABILITY = gql`
  query($productId: ID!) {
    node(id: $productId) {
      ... on Product {
        id
        variants(first: 250) {
          edges {
            node {
              id
              availableForSale
            }
          }
        }
      }
    }
  }
`;
