import React from 'react';
import styled from 'styled-components';

import RichText from '../atoms/RichText';
import CheckboxListItem from './CheckboxListItem';

import scale from '../utils/scale';

const List = styled.ul`
  list-style: none;

  ${props =>
    props.horizontal &&
    `
    display: flex;
    flex-wrap: wrap;
  `}

  li {
    ${props =>
      props.horizontal &&
      `
      padding-top: ${scale(0.5)};
      margin-right: ${scale(2.5)};
    `}
  }
`;

export default ({ horizontal = false, ...props }) => (
  <List horizontal={horizontal} {...props}>
    {props.items.map(item => {
      const { key, label, link, isDisabled } = item;
      const labelContent = label.startsWith('<p>') ? label : `<p>${label}</p>`;
      const disabled =
        props.disabled ||
        (typeof isDisabled !== 'undefined' && isDisabled === true);

      let itemProps = {
        key,
        selected: key === props.selected,
        disabled,
      };

      if (props.useLink) {
        itemProps = {
          ...itemProps,
          link,
        };
      } else {
        itemProps = {
          ...itemProps,
          handleClick: () => props.handleSelect(key),
        };
      }

      return (
        <CheckboxListItem fadeDisabled={props.fadeDisabled} {...itemProps}>
          <RichText htmlContent={labelContent} />
        </CheckboxListItem>
      );
    })}
  </List>
);
