import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import useResize from '../hooks/useResize';

import Table from './Table';
import Heading from '../atoms/Heading';
import Icon from '../atoms/Icon';
import RichTextProductDescription from '../atoms/RichTextProductDescription';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

const Accordion = styled(Table)`
  order: 10;
  margin-top: ${scale(3)};

  ${media.md`
    order: 0;
  `}
`;

const Label = styled(Heading).attrs({ headingSize: 2 })`
  position: relative;
  padding: ${scale(1.25)} ${scale(1)} ${scale(1.125)};
  cursor: pointer;

  ${media.md`
    padding: ${scale(0.5)} ${scale(1)} 0;
  `}

  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: ${scale(1.125)};
    right: ${scale(0.75)};

    ${media.md`
      top: ${scale(0.25)};
    `}
  }
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  transition: height 0.15s ease-out;
`;

const Content = styled(RichTextProductDescription)`
  margin-top: -2px;
  padding: 0 ${scale(1)} ${scale(0.5)};
`;

const Section = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.borderLighter};

  :last-child {
    ${Content} {
      padding-bottom: ${scale(1)};
    }
  }
`;

const getStructuredDescription = description => {
  const sections = description.split('<h5>');

  const data = sections
    .filter(section => section.trim().length > 0)
    .map(section => {
      const [label, content] = section.split('</h5>');

      return {
        label: label.replace(':', ''),
        content,
      };
    })
    .filter(section => section.content && section.content.trim().length > 0);

  return data;
};

export default ({ description, ...props }) => {
  const [activeSection, setSection] = useState(0);
  const sections = getStructuredDescription(description);

  const contentWrapperRefs = sections.map(() => useRef());
  const contentRefs = sections.map(() => useRef());

  const handleSectionToggle = i => {
    setSection(i === activeSection ? null : i);
  };

  const setContentHeights = () => {
    contentWrapperRefs.forEach((ref, i) => {
      if (!ref.current || !contentRefs[i].current) {
        return;
      }

      const targetHeight =
        i === activeSection ? contentRefs[i].current.offsetHeight : 0;

      // eslint-disable-next-line no-param-reassign
      ref.current.style.height = `${targetHeight}px`;
    });
  };

  useEffect(setContentHeights, [activeSection]);
  useResize(setContentHeights);

  return (
    <Accordion columns={1} header={['Produktinformationen']} {...props}>
      {sections.map(({ label, content }, i) => (
        <Section key={label} isActive={activeSection === i}>
          <Label forwardedAs="h6" onClick={() => handleSectionToggle(i)}>
            {label}

            <Icon type={activeSection === i ? 'arrowUp' : 'arrowDown'} />
          </Label>

          <ContentWrapper ref={contentWrapperRefs[i]}>
            <Content ref={contentRefs[i]} htmlContent={content} />
          </ContentWrapper>
        </Section>
      ))}
    </Accordion>
  );
};
