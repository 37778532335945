import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import { GET_VARIANTS_AVAILABILITY } from '../data/products';

import { byId } from '../utils/normalizeShopifyData';
import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Button from '../atoms/Button';
import Text from '../atoms/Text';
import Heading from '../atoms/Heading';

import HeadOverride from '../components/HeadOverride';
import InfoItemsBlock from '../components/InfoItemsBlock';
import ContentWrapper from '../components/ContentWrapper';
import SidebarLayout from '../components/SidebarLayout';
import Sidebar from '../components/Sidebar';
import SidebarActions from '../components/SidebarActions';
import SidebarInfo from '../components/SidebarInfo';
import BreadcrumbsBar from '../components/BreadcrumbsBar';
import ProductAddToCart from '../components/ProductAddToCart';
import ProductInformation from '../components/ProductInformation';
import ProductPrice from '../components/ProductPrice';
import ProductSecondaryInformation from '../components/ProductSecondaryInformation';
import CartButton from '../components/CartButton';
import Slideshow from '../components/Slideshow';

const MainContentWrapper = styled(ContentWrapper)`
  ${media.md`
    padding-left: 50%;
  `}
`;

const ProductSlideshowBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  padding: 0 0 ${scale(3)};

  ${media.md`
    position: fixed;
    top: ${props =>
      scale(
        props.theme.height.chromeBarTop + props.theme.height.breadcrumbsBar
      )};
    left: ${props => scale(props.theme.width.sidebar + 1.5)};
    width: calc(50vw - ${props => scale(props.theme.width.sidebar + 1.5 + 2)});
    height: calc(100vh - ${props =>
      scale(
        props.theme.height.chromeBarTop +
          props.theme.height.breadcrumbsBar +
          props.theme.height.chromeBarBottom
      )});
    padding: ${scale(0.5)} 0 ${scale(0.5)};
    pointer-events: none;
  `};
`;

const ProductContentBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  padding: 0 0 ${scale(3)};

  ${media.md`
    width: 50%;
    padding: 0 0 ${scale(0.5)} ${scale(2)};
  `};

  :first-child {
    ${media.md`
      display: none;
      overflow: hidden;
    `}
  }
`;

const ProductSlideshowHolder = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
`;

const ProductContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;

  ${media.md`
    height: 100%;
  `}
`;

export default class Product extends React.Component {
  handleSelectVariant(id) {
    this.setState(prevState => ({
      selectedVariant: prevState.selectedVariant !== id ? id : null,
    }));
  }

  handleSlideChange(slide) {
    this.setState({
      currentSlide: slide,
    });
  }

  constructor() {
    super();

    this.state = {
      selectedVariant: null,
      currentSlide: 1,
    };

    this.handleSelectVariant = this.handleSelectVariant.bind(this);
    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  render() {
    const product = this.props.data.allShopifyProduct.edges[0].node;

    const variantsNotVerified = product.variants.map(variant => ({
      key: variant.shopifyId,
      id: variant.shopifyId,
      isDisabled: variant.availableForSale === false,
      label: `<strong>${variant.selectedOptions[0].value}</strong>`,
    }));

    const images = product.images.map(item => {
      const { width, height } = item.localFile.childImageSharp.original;

      return {
        id: item.id,
        type: 'image',
        data: {
          fluid: item.localFile.childImageSharp.fluid,
          ratio: width / height,
          alt: item.altText,
        },
      };
    });

    const lastOverviewScrollPosition =
      this.props.location.state && this.props.location.state.scrollPosition
        ? this.props.location.state.scrollPosition
        : 0;

    return (
      <>
        <HeadOverride
          title={product.title}
          description={product.description}
          image={product.images[0].originalSrc}
          robots={this.props.pageContext.noindex ? 'noindex' : 'index'}
        />

        <BreadcrumbsBar
          breadcrumbs={this.props.pageContext.slugs}
          showHomeLink={!this.props.pageContext.isStandalone}
        />

        <SidebarLayout withBorders>
          <Sidebar>
            <SidebarActions>
              <Button
                icon="back"
                useLink
                link={`/${this.props.pageContext.slugs[0].slug}/`}
                state={{ scrollTo: lastOverviewScrollPosition }}
                secondary
              />
            </SidebarActions>

            <SidebarInfo
              data={[
                {
                  label: 'Ansicht',
                  content: product.title,
                },
              ]}
            />

            <CartButton />
          </Sidebar>

          <MainContentWrapper withSidebar>
            <ProductContentWrapper>
              <ProductSlideshowBox>
                <ProductSlideshowHolder>
                  {images.length > 0 && (
                    <Slideshow
                      relativeHeightXs
                      slides={images}
                      showSlide={this.state.currentSlide}
                      handleSlideChange={this.handleSlideChange}
                    />
                  )}
                </ProductSlideshowHolder>
              </ProductSlideshowBox>

              <ProductContentBox>
                <Heading headingSize={3} as="p">
                  Produktbezeichnung
                </Heading>
                <Text bold extended as="h1">
                  {product.title}
                </Text>

                {product.descriptionHtml && (
                  <ProductInformation description={product.descriptionHtml} />
                )}

                <ProductPrice
                  price={parseFloat(product.variants[0].price)}
                  priceCompare={
                    product.variants[0].compareAtPrice
                      ? parseFloat(product.variants[0].compareAtPrice)
                      : null
                  }
                />

                {product.availableForSale ? (
                  <Query
                    query={GET_VARIANTS_AVAILABILITY}
                    variables={{ productId: product.shopifyId }}
                    partialRefetch
                  >
                    {({ loading, data }) => {
                      // hydrate with up-to-date variant availability directly from Shopify
                      let variants = variantsNotVerified;

                      if (!loading) {
                        const variantsVerifiedById = byId(
                          data.node.variants.edges
                        );

                        variants = variantsNotVerified.map(variant => ({
                          ...variant,
                          isDisabled:
                            variantsVerifiedById[variant.id]
                              .availableForSale === false,
                        }));
                      }

                      const isVariableProduct = variants.length > 1;

                      return (
                        <ProductAddToCart
                          variants={variants}
                          productId={product.shopifyId}
                          selectedVariant={
                            isVariableProduct
                              ? this.state.selectedVariant
                              : variants[0].id
                          }
                          handleSelectVariant={this.handleSelectVariant}
                        />
                      );
                    }}
                  </Query>
                ) : (
                  <InfoItemsBlock title="Verfügbarkeit">
                    <Text bold extended>
                      Sold out
                    </Text>
                  </InfoItemsBlock>
                )}

                <ProductSecondaryInformation />
              </ProductContentBox>
            </ProductContentWrapper>
          </MainContentWrapper>
        </SidebarLayout>
      </>
    );
  }
}

export const query = graphql`
  query($productId: String!) {
    allShopifyProduct(filter: { id: { eq: $productId } }) {
      edges {
        node {
          shopifyId
          availableForSale
          title
          description
          descriptionHtml
          options {
            name
          }
          variants {
            shopifyId
            availableForSale
            price
            compareAtPrice
            selectedOptions {
              value
            }
          }
          images {
            id
            originalSrc
            altText
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                original {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
