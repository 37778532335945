import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Text from '../atoms/Text';
import IconLink from '../atoms/IconLink';

import InfoItemsBlock from './InfoItemsBlock';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

const Block = styled(InfoItemsBlock)`
  order: 20;

  ${media.md`
    order: 0;
  `}
`;

const InfoText = styled(Text)`
  margin-bottom: ${scale(2)};
  white-space: pre-line;
`;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allPrismicSiteSettings {
          edges {
            node {
              data {
                label_product_1
                content_product_1
              }
            }
          }
        }
      }
    `}
    render={({
      strings: {
        edges: [
          {
            node: { data: strings },
          },
        ],
      },
    }) => (
      <Block title={strings.label_product_1} {...props}>
        <InfoText>
          {strings.content_product_1}{' '}
          <IconLink to="/shipping-returns" type="infoInverted" />
        </InfoText>
      </Block>
    )}
  />
);
