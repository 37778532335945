export const byId = edges =>
  edges.reduce(
    (acc, { node }) => ({
      ...acc,
      [node.id]: node,
    }),
    {}
  );

export const lineItemsByProductId = edges =>
  edges.reduce(
    (acc, { node }) => ({
      ...acc,
      [node.variant.product.id]: node,
    }),
    {}
  );
