import React from 'react';
import styled from 'styled-components';

import scale from '../utils/scale';

const RichText = styled.div`
  > ul,
  > p {
    font-family: ${props => props.theme.font.base};
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.03em;
    font-weight: normal;
  }

  > ul {
    list-style: none;
  }

  .tableWrapper {
    margin: ${scale(0.5)} 0 ${scale(0.25)};
    border: 1px solid ${props => props.theme.color.borderLighter};
    width: 100%;
    overflow-x: scroll;
  }

  table {
    flex: none;
    width: 100%;
    border-spacing: 0;
    font-family: ${props => props.theme.font.base};
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.03em;
    font-weight: normal;

    tr {
      td {
        text-align: right;
        padding: ${scale(0.375)} ${scale(0.75)};
        border: 1px solid ${props => props.theme.color.borderLighter};
        border-width: 0 0 1px 1px;

        :first-child {
          width: 30%;
          border-width: 0 0 1px;
          text-align: left;
        }
      }

      &:last-child {
        td {
          border-width: 0 0 0 1px;

          :first-child {
            border-width: 0;
          }
        }
      }
    }
  }
`;

export default React.forwardRef(({ htmlContent, ...props }, ref) => {
  // wrap tables in divs
  const sanitizedContent = htmlContent
    .replace('<table', '<div class="tableWrapper"><table')
    .replace('</table>', '</table></div>');

  return (
    <RichText
      ref={ref}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      {...props}
    /> // eslint-disable-line react/no-danger
  );
});
