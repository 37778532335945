import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import formatPrice from '../utils/formatPrice';

import InfoItemsBlock from './InfoItemsBlock';
import Text from '../atoms/Text';

import scale from '../utils/scale';

const Price = styled.span`
  padding-right: ${scale(1)};

  ${props =>
    props.old &&
    `
    text-decoration: line-through;
    opacity: 0.4;
  `}
`;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allPrismicSiteSettings {
          edges {
            node {
              data {
                tax_information
              }
            }
          }
        }
      }
    `}
    render={({
      strings: {
        edges: [
          {
            node: { data: strings },
          },
        ],
      },
    }) => {
      const percentage =
        props.priceCompare &&
        Math.round(100 - (props.price * 100) / props.priceCompare);

      const priceInfo = props.priceCompare ? (
        <>
          <Price old>{formatPrice(props.priceCompare)}</Price>
          <Price>{`${formatPrice(props.price)} (${percentage}% off)`}</Price>
        </>
      ) : (
        formatPrice(props.price)
      );

      return (
        <InfoItemsBlock title="Preisinformationen">
          <Text bold extended>
            {priceInfo}
          </Text>

          <Text>{strings.tax_information}</Text>
        </InfoItemsBlock>
      );
    }}
  />
);
