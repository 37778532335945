import React from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'gatsby';
import styled from 'styled-components';

import CartContext from '../context/CartContext';

import { ADD_LINE_ITEM } from '../data/checkout';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';
import { lineItemsByProductId } from '../utils/normalizeShopifyData';

import Button from '../atoms/Button';
import Notice from './Notice';
import CheckboxList from './CheckboxList';
import InfoItemsBlock from './InfoItemsBlock';

const AddToCartButton = styled(Button)`
  margin-bottom: ${scale(1)};
  width: 100%;

  ${media.sm`
    width: auto;
  `}
`;

export default ({
  variants,
  productId,
  selectedVariant,
  handleSelectVariant,
}) => (
  <>
    {variants.length > 1 && (
      <InfoItemsBlock title="Größe/Size (select)">
        <CheckboxList
          horizontal
          items={variants}
          fadeDisabled
          selected={selectedVariant}
          handleSelect={handleSelectVariant}
        />
      </InfoItemsBlock>
    )}

    <InfoItemsBlock>
      <CartContext.Consumer>
        {({ checkoutId }) => (
          <Mutation mutation={ADD_LINE_ITEM}>
            {(addToCart, { data, loading, error }) => {
              let notice = null;

              // prepare notices
              if (
                error ||
                (!loading &&
                  data &&
                  data.checkoutLineItemsAdd.userErrors.length)
              ) {
                // check if we have any kind of errors
                notice = {
                  type: 'error',
                  children: (
                    <p>
                      Product could not be added to cart. We apologize for any
                      inconvenience.
                    </p>
                  ),
                };
              } else if (!loading && data) {
                // check if product was just added to cart
                const lineItemsById = lineItemsByProductId(
                  data.checkoutLineItemsAdd.checkout.lineItems.edges
                );

                if (typeof lineItemsById[productId] !== 'undefined') {
                  notice = {
                    singleLine: true,
                    children: (
                      <p>
                        Product added to cart.{' '}
                        <Link to="/shop/cart/">View Cart</Link>
                      </p>
                    ),
                  };
                }
              }

              return (
                <>
                  <AddToCartButton
                    icon="cart"
                    label="Add to Cart"
                    disabled={!checkoutId || selectedVariant === null}
                    onClick={() => {
                      selectedVariant !== null &&
                        !loading &&
                        addToCart({
                          variables: {
                            checkoutId,
                            variantId: selectedVariant,
                          },
                        });
                    }}
                  />

                  {notice && <Notice {...notice} />}
                </>
              );
            }}
          </Mutation>
        )}
      </CartContext.Consumer>
    </InfoItemsBlock>
  </>
);
