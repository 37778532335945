import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import scale from '../utils/scale';
import Checkbox from '../atoms/Checkbox';

const Item = styled.li`
  padding: ${scale(0.125)} 0;
`;

const ItemLink = styled(Link)`
  display: inline-flex;
  color: ${props => props.theme.color.base};
  text-decoration: none;
  cursor: pointer;

  :hover,
  :focus {
    .label {
      text-decoration: underline;
    }
  }

  ${props =>
    props.disabled &&
    `
    cursor: default;

    :hover, :focus {
      .label {
        text-decoration: none;
      }
    }
  `}

  ${props =>
    props.fadeDisabled &&
    props.disabled &&
    `
    opacity: 0.4;
  `}
`;

const ItemLabel = styled.div`
  padding-left: ${scale(1)};

  p {
    line-height: 18px;
  }
`;

const ItemCheckbox = styled(Checkbox)`
  flex: 0 0 auto;
  margin-top: 2px;
`;

export default props => {
  const { selected, link, disabled, handleClick } = props;

  const useLink = typeof link !== 'undefined';
  const isDisabled = disabled === true;
  let itemProps = {};

  if (useLink) {
    itemProps = {
      ...itemProps,
      to: link,
    };
  } else {
    itemProps = {
      ...itemProps,
      fadeDisabled: props.fadeDisabled,
      as: 'div',
      onClick: !isDisabled ? handleClick : null,
    };
  }

  if (isDisabled) {
    itemProps = {
      ...itemProps,
      as: 'div',
    };
  }

  return (
    <Item>
      <ItemLink disabled={isDisabled} {...itemProps}>
        <ItemCheckbox checked={selected} />

        <ItemLabel className="label">{props.children}</ItemLabel>
      </ItemLink>
    </Item>
  );
};
