import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid ${props => props.theme.color.border};
  width: 13px;
  height: 13px;
  background: ${props => props.theme.color.blueLight};
  vertical-align: middle;

  ${props =>
    props.checked &&
    `
    ::after,
    ::before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      display: block;
      margin-left: -7.5px;
      margin-top: -0.5px;
      width: 15px;
      height: 1px;
      background: ${props.theme.color.border};
      transform: rotate(45deg);
    }

    ::before {
      transform: rotate(-45deg);
    }
  `}
`;
